/* /src/components/player/playerDetails.module.css */

.playerDetailsContainer {
  position: flex;
  top: 20px;
  right: 20px;
  color: #fff;
  background-color: rgba(41, 41, 39, 0.9); /* Optional background */
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  z-index: 1000; /* Ensures it stays on top */
}

h2 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
}

p {
  font-size: 16px;
  margin: 8px 0;
  color: #555;
}

strong {
  color: #000;
}

.playerImageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.playerImage {
  max-width: 100px;
  border-radius: 100px;
  border: 5px solid rgb(255, 255, 255);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.imageBreakLine {
  width: 90%;
  margin: 15px auto;
  height: 2px;
  background-color: #363434;
  border: none;
}

.levelImage {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  transition: transform 0.3s ease-in-out;
}

.levelImage:hover {
  transform: scale(1.1);
}

.hitpointsImage {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  transition: transform 0.3s ease-in-out;
}

.hitpointsImage:hover {
  transform: scale(1.1);
}

.mapImage {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  transition: transform 0.3s ease-in-out;
}

.mapImage:hover {
  transform: scale(1.1);
}

.expImage {
  width: auto;
  height: 12px;
  margin-right: 10px;
  transition: transform 0.3s ease-in-out;
}

.expImage:hover {
  transform: scale(1.1);
}

.progressBarContainer {
  width: 50%;
  background-color: #e0e0e0;
  border-radius: 5px;
  margin: 10px 0;
}

.progressBar {
  width: 100%;
  height: 20px;
  background-color: #e0e0e0;
  overflow: hidden;
}

.progress {
  height: 100%;
  background-color: #4caf50;
  transition: width 0.5s ease-in-out;
}

.iconAligned {
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
}