.skillsContainer {
    border: 1px solid #ccc;
    padding: 16px;
    border-radius: 8px;
    margin-top: 20px;
    background-color: #f0f0f0;
}

.skill {
    margin-bottom: 12px;
    text-align: center;
}

.skill h4 {
    margin: 0;
    color: #333;
}

.skill p {
    margin: 4px 0;
    font-size: 14px;
}

.skill button {
    margin-top: 8px;
    padding: 8px 16px;
    font-size: 14px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.skill button:hover {
    background-color: #0056b3;
}
