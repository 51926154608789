body {
    background-color: #333;
}

.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #776c6c;
    border: 3px solid #007bff;
    border-radius: 8px;
    padding: 20px;
    width: 300px;
    margin: 0 auto;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .login-header {
    margin-bottom: 20px;
    color: #ffffff;
    font-family: Arial, sans-serif;
    text-align: center;
  }
  
  .login-input {
    width: 80%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .login-button {
    width: 80%;
    padding: 10px;
    margin: 10px 0;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
  }
  
  .login-button:hover {
    background-color: #2269b4;
  }
  
  .login-toggle-button {
    width: 80%;
    padding: 10px;
    margin: 10px 0;
    background-color: #92989e;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
  }
  
  .login-toggle-button:hover {
    background-color: #5a6268;
  }
  