/* /src/components/equipment/equipment.module.css */

.equipmentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px; /* Minimal padding for mobile */
  background-color: #f5f5f5;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  height: 100%;
  width: 100%;
  }
  
  .equipmentButton {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  .equipmentUI {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: auto;
    height: auto;
    background-color: #f0f0f0;
    border: 2px solid #333;
    padding: 20px;
  }

  .equipmentSlotsImage {
    width: 192px;
    height: 320px;
    z-index: 1;
  }
  
  .equipmentSlot {
    position: absolute;
    z-index: 2;
    border: 2px dashed #666;
    border-radius: 10%;
    background-color: rgba(255, 255, 255, 0.5);
    box-sizing: border-box; /* Ensure border is included in width/height calculations */
  }
  
  .equipmentImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10%;
    cursor: pointer;
  }
  
  .emptySlot {
    width: 100%;
    height: 100%;
    background-color: rgba(200, 200, 200, 0.3);
    border-radius: 10%;
  }
  
  .totalStats {
    text-align: center;
    font-size: 18px;
    color: #ffffff;
  }
  
  .totalStats h3 {
    margin-bottom: 10px;
  }
  