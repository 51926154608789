.shopContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #2a2a2a;
    color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .shopTitle {
    color: #fff;
    font-size: 1.8rem;
    margin-bottom: 10px;
  }
  
  .shopOwnerImage {
    width: 100px;
    height: 100px;
    object-fit: contain;
  }
  
  .shopOwner {
    color: #c7c5c5;
    font-style: italic;
    margin-bottom: 20px;
  }
  
  .shopItems {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .shopItem {
    background-color: #3a3a3a;
    padding: 15px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    width: 150px;
  }
  
  .itemImage {
    width: 32px;
    height: 32px;
    object-fit: cover;
  }
  
  .purchaseButton {
    padding: 8px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .purchaseButton:disabled {
    background-color: #777;
    cursor: not-allowed;
  }
  