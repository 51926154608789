/* /components/ui/LevelUpPopup.module.css */

.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent dark background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* Make sure it stays on top */
  }
  
  .popupContent {
    background-color: #f8f8e0; /* Light vintage yellow for a retro feel */
    border: 3px solid #ffa500; /* Orange border to make it pop */
    padding: 20px;
    width: 300px;
    max-width: 80%;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    animation: popupFadeIn 0.5s ease-in-out;
  }
  
  .popupContent p {
    font-size: 14px;
    margin: 0 0 20px;
    color: #333;
  }
  
  .popupContent button {
    padding: 10px 20px;
    font-size: 14px;
    background-color: #32cd32; /* Green for a 'positive' action */
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .popupContent button:hover {
    background-color: #228b22; /* Darker green for hover effect */
  }
  
  @keyframes popupFadeIn {
    from {
      opacity: 0;
      transform: scale(0.9);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  