/* /src/components/skills/cooking/CookingRange.module.css */

.cookingRange {
    padding: 20px;
    border: 2px solid #ffa500;
    background-color: #f8f8e0;
    border-radius: 8px;
    text-align: center;
  }
  
  .itemsContainer {
    margin: 10px 0;
    display: flex;
    gap: 10px;
    justify-content: center;
  }
  
  .cookingItem {
    padding: 10px;
    background-color: #ffebcd;
    border: 2px solid #a52a2a;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .cookingItem:hover {
    background-color: #ffd700;
  }

  .cookingItem.selected {
    border-color: #ff9800;
  }
  
  .cookButton {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #32cd32;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    color: white;
    font-weight: bold;
  }
  
  .cookButton:hover {
    background-color: #228b22;
  }
  
  .noItemsMessage {
    text-align: center;
    font-weight: bold;
    font-size: 1.2rem;
    color: #ff4d4d;
    margin-top: 20px;
  }