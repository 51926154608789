/* /src/components/currency/currency.module.css */

.currencyContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .currencyIcon {
    width: 100px;
    height: 100px;
    object-fit: contain;
  }
  
  .currencyDetails {
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .currencyButtonContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
  }
  
  .currencyButton {
    padding: 8px 12px;
    font-size: 1em;
    background-color: #19ca02;
    color: white;
    border: 2px solid #02811e;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .currencyButton:hover {
    background-color: #08a329;
    border: 2px solid #fbff00;
    transition: border 0.3s;
    transition: background-color 0.3s;
  }
  
  .clearButton {
    border: 2px solid #020202;
    background-color: #d9534f;
  }
  
  .clearButton:hover {
    background-color: #c9302c;
  }
  
  .currencyAmountHigh {
    color: green;
  }
  