/* /components/worldmap/worldMap.module.css */
.worldMapOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); /* Darken the background */
  z-index: 999; /* Ensure it overlays other content */
  display: flex;
  align-items: center;
  justify-content: center;
}

.worldMapContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mapButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  transition: transform 0.2s ease;
}

.mapButton:hover {
  transform: scale(1.1);
}

.mapIcon {
  width: 50px;
  height: 50px;
}

.mapModal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #776c6c;
  border: 2px solid #333;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mapImage {
  width: 100%;
  height: auto;
  min-width: 400px;
  margin-bottom: 10px;
}

.locationsContainer {
  position: relative;
  width: 100%;
  min-width: 400px;
  height: auto;
}

.locationMarker {
  position: absolute;
  cursor: pointer;
  width: 30px;
  height: 30px;
  background: url('../../assets/images/worldmap/icons/marker_available.png') no-repeat center;
  background-size: contain;
  transform: translate(-50%, -50%);
  transition: transform 0.3s ease;
}

.locationMarker:hover {
  transform: translate(-50%, -50%) scale(1.2);
}

.locationNameTooltip {
  position: absolute;
  transform: translate(-50%, -100%);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  white-space: nowrap;
  font-size: 0.9em;
  pointer-events: none;
  z-index: 2;
}

.closeButton {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #be3531;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.closeButton:hover {
  background-color: #c9302c;
}
