/* /src/components/gamesaves/gameSaves.module.css */

.gameSaves {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
  z-index: 1000;
}

.buttonContainer {
  display: flex;
  gap: 10px;
}

.gameButton {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  font-size: 10px; /* Smaller text for compact buttons */
}

.icon {
  width: 20px; /* Adjust icon size for smaller buttons */
  height: 20px;
}
  
  .fileInputHidden {
    display: none;
  }
  