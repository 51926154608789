.achievementsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}

.achievementList {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  width: 100%;
  max-width: 800px;
  margin-bottom: 20px;
}

.achievementItem {
  border: 3px solid red;
  border-radius: 10px;
  padding: 10px;
  text-align: center;
  background-color: #f9f9f9;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: border 0.3s;
}

.achieved {
  border: 3px solid green;
}

.achievementTitle {
  font-weight: bold;
  margin-bottom: 5px;
}

.achievementDescription {
  font-size: 0.9em;
  color: #666;
  margin-bottom: 10px;
}

.achievementButton {
  margin: 10px 0;
  padding: 8px 12px;
  font-size: 1em;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.devButton {
  background-color: #d9534f;
}

.devButton:hover {
  background-color: #c9302c;
}

.achievementButton:hover {
  background-color: #0056b3;
}

.notification {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #4caf50;
  color: white;
  padding: 15px 20px;
  border-radius: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  font-weight: bold;
  transition: opacity 0.5s ease, transform 0.5s ease;
  opacity: 1;
  z-index: 1000;
}

.notification-hidden {
  opacity: 0;
  transform: translateX(-50%) translateY(20px);
}
