.uiContainer {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 10;
}

.uiButton {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.uiButton:hover {
  background-color: #0056b3;
}

.uiButton:active {
  background-color: #004494;
}

.playerDetailsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 10;
  gap: 15px;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-end;
}

.gameButton {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #a39c9c;
  border: 2px solid #007bff;
  cursor: pointer;
  transition: background-color 0.3s;
}

.gameButton:hover {
  background-color: #c5c2c2;
  border-color: #fbff00;
}

.icon {
  width: 20px;
  height: 20px;
}

.inventoryOverlay {
  position: fixed;
  top: 0;
  left: -100%; /* Hide by default */
  height: 100%;
  max-width: 60%;
  min-width: 220px;
  background-color: #ffffff;
  box-shadow: 5px 0 15px rgba(0, 0, 0, 0.3);
  transition: left 0.3s ease;
  z-index: 999;
}

.equipmentOverlay {
  position: fixed;
  top: 0;
  left: -100%; /* Hide by default */
  height: 100%;
  max-width: 60%;
  min-width: 220px;
  background-color: #ffffff;
  box-shadow: 5px 0 15px rgba(0, 0, 0, 0.3);
  transition: left 0.3s ease;
  z-index: 999;
}

.inventoryOverlay.open,
.equipmentOverlay.open {
  left: 0; /* Slide into view */
}
